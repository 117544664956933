.gameContainer {
  max-width: 700px;
  max-height: 700px;
  display: contents;
}

.title {
  text-align: center;
}

.content {
  margin-top: 20px;
  display: flex;
  flex-flow: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
}

.gameStatusContainer {
  display: flex;
  justify-content: space-between;
}

.turn {
  margin-top: 40px;
}

.goatCount {
  margin-left: 20px;
  margin-bottom: 40px;
  font-size: 28px;
  display: flex;
}

.boardContainer {
  margin: 0 auto;
  display: grid;
  overflow: hidden;
}

.boardContainer > canvas,
.boardContainer > img {
  position: absolute;
}
