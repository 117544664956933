.pill {
  background-color: #FDD037;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  min-height: 50px;
}

button:disabled {
  cursor: not-allowed;
}

button.pill,
a.pill {
  box-shadow: 0px -4px 0px 0px red;
  text-decoration: none;
  color: black;
}

button.pill.activePill,
a.pill.activePill {
  box-shadow: 0px -4px 0px 0px green;
}

button:disabled.pill,
a:disabled.pill {
  color: rgb(63, 62, 62);
  background-color: #997f1f;
}

button:hover:enabled.pill,
a:hover.pill {
  box-shadow: 0px -8px 0px 0px red;
}

button:hover:enabled.pill.activePill,
a:hover.pill.activePill {
  box-shadow: 0px -8px 0px 0px green;
}

button:hover:enabled:active.pill,
a:hover:active.pill {
  box-shadow: 0px -4px 0px 0px green;
}

button:hover:enabled:active.pill.activePill,
a:hover:active.pill.activePill {
  box-shadow: 0px -4px 0px 0px red;
}

/**
 * Thankyou https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.loading {
  display: flex;
  justify-content: center;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: red;
  color: red;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fa5e5e;
  color: #fa5e5e;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
