.container,
.designation {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  margin-top: 20px;
}

.title {
  color: #ff6011;
  font-size: 36px;
  font-weight: 800;
  text-shadow: 0px 2px 4px #a7a19f;
}

.levelButton,
.homeButton {
  padding: 0 20px;
}

.levelButton,
.homeButton,
.designation,
.gameCodeForm {
  color: black;
  margin: 25px 0;
  cursor: pointer;
}

.joinGameError,
.joinGameLoading {
  color: rgb(63, 62, 62);
  margin: 25px 0;
  line-height: 50px;
}

.gameCodeInput {
  padding: 0 20px;
  height: 100%;
}

.joinGameError,
.joinGameLoading,
.gameCodeInput,
.homeButton,
.selectLevel,
.gameCodeForm {
  min-width: 200px;
}

.selectLevel {
  margin: 25px 0;
  height: 50px;
  justify-content: center;
  display: flex;
  color: black;
  align-items: center;
}

.levels {
  display: flex;
  width: 200px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.designation {
  height: 50px;
  width: 200px;
}
